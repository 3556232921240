// import axios from "axios";

// const baseUrl = axios.create({
//   baseURL: "http://localhost:8080",
// });
// export default baseUrl;

// const baseUrl = 'http://localhost:8080/eLearning';
// window.baseUrl = baseUrl; 
// export default baseUrl;

// http://93.127.199.121:5202/eLearning/swagger-ui/index.html
const baseUrl = 'https://testtomcat.vsmartengine.com/eLearning';
export default baseUrl;
// https://testtomcat.vsmartengine.com/elearning/
// const baseUrl = 'http://localhost:8080/eLearning';
// export default baseUrl;

// const baseUrl = 'https://lhdemotomcat.vsmartengine.com/elearning';
// export default baseUrl;



// spring.datasource.url=jdbc:postgresql://localhost:5201/testlearnhub
